import React from 'react';
import Container from '@/components/common/container';
import CtaLink from '@/components/common/cta-link';
import { GatsbyImage } from 'gatsby-plugin-image';
import Fade from '@/components/common/fade';

const Stories = ({ stories }) => {
  return (
    <div className="pb-8 pt-16 md:pb-32 md:pt-20 lg:pb-40">
      <Container>
        <Fade>
          <div className="-mx-4 flex flex-wrap justify-center">
            {stories &&
              stories.map((story, idx) => {
                return (
                  <div
                    className={`mb-6 w-full max-w-lg px-4 lg:w-1/2 lg:max-w-none ${
                      idx % 2 !== 0 ? 'lg:translate-y-20 lg:transform' : ''
                    }`}
                  >
                    <div className="rounded-lg bg-gradient-to-br from-y via-rm to-pb p-0.5 shadow-feature">
                      <div className="rounded-md bg-white px-6 py-8 lg:px-12 lg:py-10">
                        <div>
                          <div className="item-center -mx-1 flex justify-center text-grey-700">
                            <div className="mb-6 w-auto lg:mb-8">
                              <GatsbyImage
                                alt={story.company}
                                image={story.logo.asset.gatsbyImageData}
                              />
                            </div>
                          </div>
                        </div>
                        <p className="text-center text-lg lg:text-xl">
                          {'"'}
                          {story.blockquote}
                          {'"'}
                        </p>
                        <div className="-mx-2 mb-8 mt-8 flex flex-col items-center lg:mb-0">
                          <div className="mb-4 w-auto px-2">
                            <GatsbyImage
                              className="h-20 w-20 rounded-full bg-purple-100"
                              image={
                                story.customerRep.image.asset.gatsbyImageData
                              }
                              alt={story.customerRep.name}
                            />
                          </div>
                          <div className="w-auto px-2 text-center">
                            <p className="text-lg font-bold">
                              {story.customerRep.name}
                            </p>
                            <p>{story.customerRep.role}</p>
                          </div>
                        </div>
                        <p className="mt-6 text-center">
                          <a
                            href={`/customer-stories/${story.slug.current}`}
                            className="inline-block rounded-lg border border-primary-600 bg-primary-600 px-4 py-2.5 font-inter text-sm font-semibold text-white hover:opacity-70 xl:px-6"
                          >
                            Check Customer Story
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Fade>
      </Container>
    </div>
  );
};

export default Stories;
