import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '@/components/common/layout';
import { useStaticQuery, graphql } from 'gatsby';
import Hero from '@/components/customer-stories/hero';
import Stories from '@/components/customer-stories/stories';
import SEO from '@/components/seo';

const CustomerStories: React.FC<PageProps> = () => {
  const data = useStaticQuery(graphql`
    {
      sanityCustomerStoryList {
        id
        seo {
          metaTitle
          metaDescription
          image {
            asset {
              gatsbyImageData(width: 1280)
              url
            }
          }
        }
        pageHeroSection {
          title {
            _key
          }
          subtitle
          _rawTitle(resolveReferences: { maxDepth: 10 })
        }
      }

      allSanityCustomerStories(sort: { fields: _createdAt, order: DESC }) {
        nodes {
          id
          slug {
            current
          }
          industry
          company
          blockquote
          slug {
            current
          }
          seo {
            metaTitle
            metaDescription
            image {
              asset {
                gatsbyImageData(width: 1280)
                url
              }
            }
          }
          logo {
            asset {
              gatsbyImageData(height: 62)
            }
          }
          customerRep {
            image {
              asset {
                gatsbyImageData
              }
            }
            name
            role
          }
          _createdAt(formatString: "")
        }
      }
    }
  `);

  const { nodes } = data.allSanityCustomerStories;

  const page = data.sanityCustomerStoryList;

  const { seo } = page;

  return (
    <Layout>
      <SEO title={page.seo.metaTitle} description={page.seo.metaDescription} />
      <div className="mx-auto max-w-[1640px] bg-[url(/customer-stories-sm.svg)] bg-[right_top_10rem] bg-no-repeat md:bg-[url(/customer-stories-md.svg)] md:bg-[right_top_5rem] lg:bg-right-top xl:bg-[url(/customer-stories-lg.svg)]">
        <Hero data={data.sanityCustomerStoryList} />
        <Stories stories={nodes} />
      </div>
    </Layout>
  );
};

export default CustomerStories;
