import React from 'react';
import Container from '@/components/common/container';
import { PortableText } from '@portabletext/react';
import GradientText from '@/components/common/gradient-text';
import Fade from '@/components/common/fade';

const heroTitlePortableText = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <GradientText className="block md:inline" hoverable={false}>
        {children}
      </GradientText>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const Hero = ({ data }) => {
  return (
    <div className="pb-8 pt-16 md:pb-12 md:pt-24 lg:pt-32 xl:pt-40">
      <Container>
        <Fade>
          <div className="flex flex-col items-center text-center">
            <div className="max-w-3xl">
              <h1 className="text-[28px] font-semibold leading-tight md:text-[40px] lg:text-[56px] xl:text-[74px]">
                <PortableText
                  value={data.pageHeroSection._rawTitle}
                  components={heroTitlePortableText as any}
                />
              </h1>
              <p className="mt-5 font-light md:text-lg lg:mt-8">
                {data.pageHeroSection.subtitle}
              </p>
            </div>
          </div>
        </Fade>
      </Container>
    </div>
  );
};

export default Hero;
